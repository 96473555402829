<template>
  <div>
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-3">
        <h3 class="mb-0">Report Download</h3>
        <div>
          <slot name="actions"></slot>
        </div>
      </div>
      <div data-cy="report-download">
        <div class="block-content block-content-full">
          <div>
            <div class="row justify-content-center align-items-center">
              <div class="col-lg-12 text-center mb-5">
                <Spinner v-if="loading || report.status === 'preparing'">Preparing report... This may take a few minutes.</Spinner>
                <Spinner v-else-if="report.status === 'generating'">Generating report... This may take a few minutes.</Spinner>
                <div v-else-if="report.status === 'ready'">
                  <div class="font-w600 font-size-h4 mb-3"><i class="fa fa-check-circle text-success mr-2"></i> Report ready!</div>
                  <p>{{ report.fileName }}</p>
                  <a class="btn btn-primary btn-lg" :class="{ disabled: !downloadUrl }" :href="downloadUrl"
                    ><i class="fa fa-fw fa-download"></i> Download</a
                  >
                </div>

                <div v-else-if="report.status === 'error'">
                  <div class="font-w600 font-size-h4 mb-2"><i class="fa fa-times-circle text-danger"></i> Report failed to generate.</div>
                  <div>Please contact support.</div>
                </div>
              </div>
              <div v-if="additionalParams.length > 0" class="col-lg-4">
                <div>
                  <h3 class="font-size-h5 mb-2 text-muted">Report Parameters</h3>
                  <p>
                    These are the parameters that were used to generate this report. These will affect the data within the report, such as consumption
                    values.
                  </p>
                  <ul class="list-group">
                    <li v-for="additionalParam in additionalParams" :key="additionalParam.key" class="list-group-item">
                      <strong>{{ additionalParam.name }} | </strong> {{ additionalParam.value.toString() | capitalize }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Spinner from '@/components/SpinnerLogo';

export default {
  name: 'ReportDownload',
  components: {
    Spinner
  },
  props: {
    generateReport: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    isPortfolio: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      poll: {},
      fetchedDownloadUrl: false,
      downloadUrl: ''
    };
  },
  computed: {
    ...mapGetters({
      report: 'report/report'
    }),
    additionalParams() {
      const params = [
        {
          key: 'sourceData',
          name: 'Data Source'
        },
        {
          key: 'granularity',
          name: 'Granularity'
        },
        {
          key: 'simulated',
          name: 'Show Simulated Data'
        },
        {
          key: 'emissions',
          name: 'Show Emission Data'
        }
      ];

      return params.reduce(
        (acc, param) =>
          this.report.metadata && this.report.metadata[param.key]
            ? [...acc, { key: param.key, name: param.name, value: this.report.metadata[param.key] }]
            : acc,
        []
      );
    }
  },
  beforeDestroy() {
    this.endPolling();
    this.resetState();
  },
  async mounted() {
    let report = {};

    const { id, ...additionalParams } = this.$route.query;

    if (id) {
      report = await this.getReport({ id });
      this.getDownloadUrl();
    } else {
      report = await this.generateReport({
        type: this.$route.params.reportType,
        id: this.$route.params.id,
        params: additionalParams
      });

      this.$emit('generate', report);
    }

    if (report?.status !== 'ready') this.startPolling();
  },
  methods: {
    ...mapActions({
      getReport: 'report/get',
      download: 'report/download',
      downloadPortfolio: 'report/downloadPortfolio'
    }),
    ...mapMutations({
      resetState: 'report/RESET_STATE'
    }),
    async getDownloadUrl() {
      if (!this.fetchedDownloadUrl) {
        this.fetchedDownloadUrl = true;

        const download = this.isPortfolio ? await this.downloadPortfolio({ id: this.report._id }) : await this.download({ id: this.report._id });

        this.downloadUrl = download.downloadUrl;
      }
    },
    startPolling() {
      this.poll = setInterval(async () => {
        const report = await this.getReport({ id: this.$route.query.id });
        if (report.status === 'ready' || report.status === 'error') {
          this.endPolling();
          this.getDownloadUrl();
        }
      }, 5000);
    },
    endPolling() {
      clearInterval(this.poll);
      this.poll = null;
    }
  }
};
</script>
