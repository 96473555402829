<template>
  <div>
    <ReportDownload
      :loading="loadingAction.generatePortfolioReport"
      :generate-report="generatePortfolioReport"
      :is-portfolio="true"
      @generate="onGenerateReport"
    >
      <template v-slot:actions>
        <router-link active-class="active" :to="{ name: 'portfolio-reports', params: { id: assetGroup._id } }" class="btn btn-light mr-3"
          ><i class="fa fa-fw fa-arrow-left"></i> Back to Reports</router-link
        >
      </template>
    </ReportDownload>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ReportDownload from '@/components/reports/ReportDownload';

export default {
  name: 'PortfolioReportDownload',
  components: {
    ReportDownload
  },
  computed: {
    ...mapGetters({
      assetGroup: 'assetGroup/assetGroup',
      loadingAction: 'report/loadingAction',
      report: 'report/report'
    })
  },
  methods: {
    ...mapActions({
      generatePortfolioReport: 'report/generatePortfolioReport'
    }),
    onGenerateReport(report) {
      this.$router.replace({ name: 'portfolio-reports-download', params: this.$route.params, query: { ...this.$route.query, id: report._id } });
    }
  }
};
</script>
